<template>
    <b-modal no-close-on-backdrop no-close-on-esc id="modal_especies_image" content-class="modal-especies-rounded-border" header-class="modal-especies-header-bg-color" size="md" hide-footer :style="{ boxShadow: '0 1px 0 0  black', border: 'none', borderBottom: '1px solid  black !important' }" >
        <template #modal-header="{ close }">
            <div class="mt-3 ml-2">
                <p :style="{fontSize:'0.7rem'}">Crédito: {{author}}</p>
                <p class="especie-nome" v-html="cientificName"></p>
            </div>
            <!-- Emulate built in modal header close button action -->
            <button type="button" aria-label="Close" class="close" title="Fechar" @click="close()" :style="{fontFamily:'arial, helvetica, sans-serif',fontWeight:'normal',color:'black',fontSize:'1.5rem'}">[×]</button>
        </template>
        <div class="card" :style="{borderRadius:'2rem'}">
            <img :src="especieImage" alt="Especie" :style="{borderRadius: '0 0 2rem 2rem'}">
        </div>
    </b-modal>
</template>

<script>
import barramento from '@/barramento'

export default {
    data() {
        return {
            especieImage: '',
            author:'',
            cientificName:''
        }
    },
    created() {
        barramento.$on('modal_especies_image', item => {
            this.especieImage = item.downloadUrl
            this.author = item.authorName
            this.cientificName = item.cientificName
            this.$bvModal.show('modal_especies_image')
        })
    }
}
</script>

<style lang="scss">
    .modal-especies-header-bg-color {
        background-color: #D7FF9B;
        border-top-left-radius: calc(2rem - 1px) !important;
        border-top-right-radius: calc(2rem - 1px) !important;
    }

    // .modal-content  {

    // }
    .modal-especies-rounded-border {
        -webkit-border-radius: 2rem !important;
        -moz-border-radius: 2rem !important;
        border-radius: 2rem !important;

        box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
        -webkit-box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
        -moz-box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
    }

    #modal_especies_image {
        .close-button button {
            background-color: transparent;
            border: none;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10000;
        }
        .session-gap {
            height: 15px;
        }
        .info {
            font-size: 14px;
            color: #646464
        }
        .modal-body{
            padding: 0 !important;

            box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
            -webkit-box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
            -moz-box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
        }
    }

</style>